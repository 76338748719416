import { DefaultTheme, createGlobalStyle } from "styled-components";
import { Inter, Roboto_Mono } from "@next/font/google";

export const inter = Inter({
  subsets: ["latin"],
});

export const robotoMono = Roboto_Mono({
  subsets: ["latin"],
});

export const lightTheme: DefaultTheme = {
  shadows: {
    paper:
      "0px 1px 5px rgba(0, 0, 0, 0.1), 0px 3px 1px -2px rgba(0, 0, 0, 0.06), 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.16)",
    basic: "0px 2px 2px rgba(40, 40, 40, 0.25)",
    button: "0px 1px 2px rgba(38, 41, 44, 0.08)",
    button_active: "0px 1px 2px rgba(38, 41, 44, 0.08)",
    footer: "0px -1px 10px -1px rgba(25, 36, 53, 0.08)",
    card_hover:
      "drop-shadow(1px 1px 2px rgba(32, 18, 82, 0.05)) drop-shadow(1px 1px 2px rgba(32, 18, 82, 0.05))",
    card_active:
      "drop-shadow(1px 1px 8px rgba(244, 127, 174, 0.05)) drop-shadow(1px 1px 4px rgba(210, 53, 119, 0.05))",
  },
  border_radius: {
    small: "2px",
    normal: "4px",
  },
  screen_sizes: {
    small: 900,
  },

  colors: {
    active: {
      default: "#317AE2",
      light: "#DEEAFA",
    },
    divider: {
      light: "#E8E6F0",
      default: "#BFBBCE",
      strong: "#A6A2B8",
    },
    fill: {
      base: "#FFFFFF",
      baseInverted: "#1D1E35",
      baseSecondary: "#F6F5F8",
      light: "#EAE8EF",
      medium: "#B5B2BE",
      mediumLight: "#D0CDD8",
      mediumStrong: "#9B98A4",
      strong: "#706D77",
      white: "#FFFFFF",
      lightTransparent: "rgba(56, 39, 97, 0.12)",
      baseSecondaryTransparent: "rgba(27, 16, 49, 0.05)",
      transparent: "rgba(27, 16, 49, 0.00)",
      pipedrivePill: "#E4E4E5",
    },
    icon: {
      primary: "#192435",
      secondary: "#656E7A",
    },
    info: {
      background: "#E2DEF3",
      border: "#C5C0D7",
      default: "#563FA8",
    },
    negative: {
      background: "#FED9E8",
      backgroundLight: "#FFF1F0",
      border: "#FDC4BF",
      default: "#F94839",
      hover: "#F85749",
      muted: "#FACBC8",
      strong: "#D64236",
    },
    positive: {
      background: "#D5EFE6",
      backgroundLight: "#EFFCF7",
      border: "#B5DBCC",
      default: "#017737",
      hover: "#268f56",
      muted: "#7C9D91",
      strong: "#15704F",
    },
    primary: {
      default: "#231849",
      hover: "#3F3071",
      muted: "#463F60",
      strong: "#1C1145",
    },
    secondary: {
      background: "#FFA5C8",
      default: "#ED6FA2",
      hover: "#F696BC",
    },
    text: {
      linkInverted: "#ED6FA2",
      linkMuted: "#D28EAB",
      muted: "#999FA7",
      primary: "#192435",
      primaryInverted: "#FFFFFF",
      secondary: "#656E7A",
      mutedTransparent: "rgba(109, 117, 132, 0.58)",
    },
    warning: {
      backgroundLight: "#FFF9DD",
      border: "#ECE4C1",
      default: "#E9971D",
    },
  },
};

export const darkTheme: DefaultTheme = {
  shadows: {
    paper:
      "0px 1px 5px rgba(255, 255, 255, 0.1), 0px 3px 1px -2px rgba(255, 255, 255, 0.06), 0px 2px 2px rgba(255, 255, 255, 0.05), 0px 0px 1px rgba(255, 255, 255, 0.16)",
    basic: "0px 2px 2px rgba(10, 10, 10, 0.25)",
    button: "0px 1px 2px rgba(18, 21, 24, 0.08)",
    button_active: "0px 1px 2px rgba(18, 21, 24, 0.08)",
    footer: "0px -1px 10px -1px rgba(10, 21, 38, 0.08)",
    card_hover:
      "drop-shadow(1px 1px 2px rgba(132, 148, 205, 0.05)) drop-shadow(1px 1px 2px rgba(132, 148, 205, 0.05))",
    card_active:
      "drop-shadow(1px 1px 8px rgba(81, 173, 124, 0.05)) drop-shadow(1px 1px 4px rgba(145, 206, 186, 0.05))",
  },
  border_radius: {
    small: "2px",
    normal: "4px",
  },
  screen_sizes: {
    small: 900,
  },

  colors: {
    active: {
      default: "#317AE2",
      light: "#DEEAFA",
    },
    divider: {
      light: "#3C3D45",
      default: "#3C3D45",
      strong: "#2A2C35",
    },
    fill: {
      base: "#1E2029",
      baseInverted: "#FFFFFF",
      baseSecondary: "#2A2B3E",
      light: "#242226",
      medium: "#4A4D51",
      mediumLight: "#2F3237",
      mediumStrong: "#6b6b6b",
      strong: "#8F8C88",
      white: "#1E2029",
      lightTransparent: "rgba(200, 200, 200, 0.12)",
      baseSecondaryTransparent: "rgba(228, 239, 255, 0.05)",
      transparent: "rgba(228, 239, 255, 0.00)",
      pipedrivePill: "#35373F",
    },
    icon: {
      primary: "#FFFFFF",
      secondary: "#656E7A",
    },
    info: {
      background: "#563FA8",
      border: "#3C3D45",
      default: "#DEEAFA",
    },
    negative: {
      background: "#F94839",
      backgroundLight: "#FFF1F0",
      border: "#FDC4BF",
      default: "#F94839",
      hover: "#F85749",
      muted: "#FACBC8",
      strong: "#D64236",
    },
    positive: {
      background: "#017737",
      backgroundLight: "#EFFCF7",
      border: "#B5DBCC",
      default: "#017737",
      hover: "#268f56",
      muted: "#7C9D91",
      strong: "#15704F",
    },
    primary: {
      default: "#1C1145",
      hover: "#3F3071",
      muted: "#463F60",
      strong: "#231849",
    },
    secondary: {
      background: "#F696BC",
      default: "#ED6FA2",
      hover: "#FFA5C8",
    },
    text: {
      linkInverted: "#FFFFFF",
      linkMuted: "#D28EAB",
      muted: "#999FA7",
      primary: "#FFFFFF",
      primaryInverted: "#192435",
      secondary: "#656E7A",
      mutedTransparent: "rgba(109, 117, 132, 0.58)",
    },
    warning: {
      backgroundLight: "#FFF9DD",
      border: "#ECE4C1",
      default: "#E9971D",
    },
  },
};

export const GlobalStyle = createGlobalStyle<{ isDark?: boolean }>`
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  body {
    margin: 0;
    overflow-x: hidden;
    font-family: "Inter", sans-serif;
    font-style:normal; 
    line-height: 20px;
    font-weight: 450;
    font-size: 14px;
    color: ${(props) => props.theme.colors.text.primary};
    background-color: ${(props) => props.theme.colors.fill.base};

      *::-webkit-scrollbar {
        width: 6px;
      }

      *::-webkit-scrollbar-track {
        background: ${(props) => props.theme.colors.fill.base} !important;
        
      }

      *::-webkit-scrollbar-thumb {
        background-color: ${(props) =>
          props.theme.colors.fill.medium} !important;

        border-radius: 10px;
      }
    }
  
    * {
      scrollbar-width: thin !important;
      scrollbar-color: ${(props) => props.theme.colors.fill.mediumStrong} ${(
  props
) => props.theme.colors.fill.base} !important;

      /* FIREFOX */
      @-moz-document url-prefix() {
        * {
          scrollbar-width: auto !important;
        }
      }
    }  




  
  /* Font styles */ 
  a {
    font-weight: 600;
    text-decoration: none;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #2A69D1;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 28px;
  }
  
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 28px;
  }
  
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  
  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  
  p {
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 20px;
  }
  
  strong {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  
  .large-text {
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 24px;
  }
  
  .large-text-strong {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  
  .small-text {
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 16px;
  }
  
  .small-text-strong {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
  
  .caption {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    text-transform: uppercase;
  }
  
  .monospace {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
  
  
    /* Input styles */ 
    textarea {
      width: 100%;
      min-height:100px; 
      border: 1px solid ${(props) => props.theme.colors.divider.default};
      background-color: ${(props) => props.theme.colors.fill.base};
      color: ${(props) => props.theme.colors.fill.baseInverted};
      caret-color: ${(props) => props.theme.colors.fill.baseInverted};
      border-radius: 4px;
      padding: 6px 6px;
      box-sizing: border-box;
      font-family: inherit;
      resize:vertical; 
    }
    label {
      font-family: inherit;
      font-weight: 600;
      line-height: 1.5rem;
    }
    input[type="checkbox"], input[type="radio"]  {
      accent-color: ${(props) => props.theme.colors.active.default};
    }
    input:not([type="radio"]):not([type="checkbox"])
     {
      min-height:38px;
      width: 100%;
      border: 1px solid ${(props) => props.theme.colors.divider.default};
      border-radius: 4px;
      padding: 6px 6px;
      box-sizing: border-box;
      font-family: inherit;
      background-color: ${(props) => props.theme.colors.fill.base};
      color: ${(props) => props.theme.colors.fill.baseInverted};
      caret-color: ${(props) => props.theme.colors.fill.baseInverted};
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
      cursor: pointer;
      border-radius: 4px;
      margin-right: 2px;
      opacity: 0.8;
      filter: ${(props) => (props.isDark ? "invert(100%)" : "invert(0%)")};
    }

    input[type="date"]::-webkit-calendar-picker-indicator:hover {
      opacity: 1
    }

    input:active,
    input:focus,
    :is(input, textarea):focus-visible {
      outline: ${(props) => props.theme.colors.active.default} solid 1px;
    }
  
    input:disabled {
      background-color: ${({ theme }) => theme.colors.fill.baseSecondary}; 
      color: ${({ theme }) => theme.colors.text.muted};
      cursor: not-allowed;
    }  
  `;
