import {
  GlobalStyle,
  darkTheme,
  inter,
  lightTheme,
  robotoMono,
} from "@/appConfig/styles";
import type { ReactElement, ReactNode } from "react";

import type { AppProps } from "next/app";
import type { NextPage } from "next";
import { SWRConfig } from "swr";
import { ThemeProvider } from "styled-components";
import { appWithTranslation } from "next-i18next";

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

interface AppPropsWithLayout extends Omit<AppProps, "Component"> {
  Component: NextPageWithLayout;
  isThemeDark: boolean;
}

function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout || ((page) => page);
  return getLayout(
    <SWRConfig
      value={{
        keepPreviousData: true,
        onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
          if (error.status === 404) return;

          if (retryCount >= 2) return;

          setTimeout(() => revalidate({ retryCount }), 500);
        },
      }}
    >
      <ThemeProvider theme={pageProps.isThemeDark ? darkTheme : lightTheme}>
        <GlobalStyle isDark={pageProps.isThemeDark} />
        <Component {...pageProps} />
      </ThemeProvider>
      <style jsx global>{`
        * {
          font-family: ${inter.style.fontFamily}, sans-serif;
        }

        .monospace {
          font-family: ${robotoMono.style.fontFamily}, monospace;
        }
      `}</style>
    </SWRConfig>
  );
}

export default appWithTranslation(App as any);
